/*
 * 配布書類セット
 */

export default {
  state: {
    distributionDocuments: [],
    distributionDocument: {},
    distributionDocumentFiles: [],
    distributionDocumentParticipants: [],
    distributionDocumentReplyFiles: [],
  },

  getters: {
    getDistributionDocuments: (state) => state.distributionDocuments,
    getDistributionDocument: (state) => state.distributionDocument,
    getDistributionDocumentFiles: (state) => state.distributionDocumentFiles,
    getDistributionDocumentParticipants: (state) => state.distributionDocumentParticipants,
    getDistributionDocumentReplyFiles: (state) => state.distributionDocumentReplyFiles,
  },

  mutations: {
    saveDistributionDocuments(state, data) {
      state.distributionDocuments = data
    },

    saveDistributionDocument(state, data) {
      state.distributionDocument = data
    },

    saveDistributionDocumentFiles(state, data) {
      state.distributionDocumentFiles = data
    },

    saveDistributionDocumentParticipants(state, data) {
      state.distributionDocumentParticipants = data
    },

    saveDistributionDocumentReplyFiles(state, data) {
      state.distributionDocumentReplyFiles = data
    }
  },

  actions: {
    // 配布書類セット一覧取得
    async fetchDistributionDocuments({ commit }, params) {
      const article_id = params.articleId
      const query = params?.contractId
        ? `?contract_info_id=${params.contractId}`
        : ''

      const res = await this.$axios.get(`/distribution_documents/article/${article_id}${query}`)
      commit('saveDistributionDocuments', res.data?.body?.data || [])
    },

    // 配布書類セット情報取得
    async fetchDistributionDocument({ commit }, params) {
      const distribution_document_id = params.distributionDocumentId

      const res = await this.$axios.get(`/distribution_documents/${distribution_document_id}`)
      commit('saveDistributionDocument', res.data?.body?.data || {})
      // エラーハンドリングしたいので、レスポンスを返す
      return res
    },

    // 配布書類一覧取得
    async fetchDistributionDocumentFiles({ commit }, params) {
      const distribution_document_id = params.distributionDocumentId

      const res = await this.$axios.get(`/distribution_documents/${distribution_document_id}/files`)
      commit('saveDistributionDocumentFiles', res.data?.body?.data || [])
    },

    // 配布書類セット関係者一覧取得
    async fetchDistributionDocumentParticipants({ commit }, params) {
      const distribution_document_id = params.distributionDocumentId

      const res = await this.$axios.get(`/distribution_documents/${distribution_document_id}/participants`)
      commit('saveDistributionDocumentParticipants', res.data?.body?.data || [])
    },

    // 配布書類ファイル取得
    async fetchDistributionDocumentFile({ commit }, params) {
      const { distribution_document_id, distribution_file_id } = params
      const res = await this.$axios.get(`/distribution_documents/${distribution_document_id}/files/${distribution_file_id}/s3_presigned_url`)
      // エラー時は、undefinedを返す
      return res?.data?.body?.data?.url
    },

    // 配布書類セット削除
    async deleteDistributionDocument({ commit }, params) {
      const distribution_document_id = params.distributionDocumentId
      return await this.$axios.delete(`/distribution_documents/${distribution_document_id}`)
    },

    // 配布書類セット一時保存
    async updateDistributionDocument({ commit }, payload) {
      const { params } = payload
      const distribution_document_id = payload?.distribution_document_id || null

      const uri = distribution_document_id !== null
        ? `/distribution_documents/${distribution_document_id}`
        : '/distribution_documents'

      return await this.$axios.post(uri, params)
    },

    // 配布書類セット関係者更新
    async updateDistributionDocumentParticipants({ commit }, payload) {
      const { params, distribution_document_id } = payload
      return await this.$axios.put(`/distribution_documents/${distribution_document_id}/participants`, params)
    },

    // 配布書類セット配布書類更新
    async updateDistributionDocumentFiles({ commit }, payload) {
      const { params, distribution_document_id } = payload
      const config = payload?.config || null
      return await this.$axios.post(`/distribution_documents/${distribution_document_id}/files`, params, config)
    },

    // 配布書類セット送信
    async sendDistributionDocument({ commit }, payload) {
      const { distribution_participant_id, distribution_document_id } = payload
      const config = payload?.config || null
      return await this.$axios.post(`/distribution_documents/${distribution_document_id}/participants/${distribution_participant_id}/notifications`, null, config)
    },

    // 配布書類セット関係者送信取消
    async cancelDistributionDocumentParticipant({ commit }, params) {
      const { distribution_document_id, distribution_participant_id } = params
      return await this.$axios.put(`/distribution_documents/${distribution_document_id}/participants/${distribution_participant_id}`)
    },

    // 配布書類セット関係者追加
    async addDistributionDocumentParticipant({ commit }, payload) {
      const { distribution_document_id, params } = payload
      const config = payload?.config || null
      return await this.$axios.post(`/distribution_documents/${distribution_document_id}/participants`, params, config)
    },

    // 配布書類返信ファイル取得
    async fetchDistributionDocumentReplyFileUrl({ commit }, params) {
      const {
        distribution_document_id,
        distribution_file_id,
        distribution_reply_file_id,
      } = params

      const url = `/distribution_documents/${distribution_document_id}/files/${distribution_file_id}/reply_files/${distribution_reply_file_id}/s3_presigned_url`
      const res = await this.$axios.get(url)

      // エラー時は、undefinedを返す
      return res?.data?.body?.data?.url
    },

    // 配布書類関係者返信ファイル情報一覧取得
    async fetchDistributionDocumentReplyFiles({ commit }, params) {
      const { distribution_document_id, distribution_file_id } = params

      const url = `/distribution_documents/${distribution_document_id}/files/${distribution_file_id}/participants/reply_files`
      const res = await this.$axios.get(url)
      commit('saveDistributionDocumentReplyFiles', res.data?.body?.data || [])
    },

  },
}

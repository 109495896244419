export default {

  state: {
    distributionDocuments: [],
  },

  getters: {
    getDistributionDocuments: (state) => {
      return state.distributionDocuments
    },
  },

  mutations: {
    saveDistributionDocuments(state, data) {
      state.distributionDocuments = data
    },
  },

  actions: {
    // [顧客]配布書類情報一覧取得
    async fetchDistributionDocuments({ commit }) {
      const res = await this.$axios.get('/customer/distribution_documents')
      commit('saveDistributionDocuments', res.data?.body?.data || [])
    },

    // [顧客]配布書類ダウンロード
    async downloadDistributionDocumentsFile({ commit }, payload) {
      const { distribution_document_id, distribution_file_id } = payload
      return await this.$axios.get(`/customer/distribution_documents/${distribution_document_id}/files/${distribution_file_id}/s3_presigned_url`)
    },

    // [顧客]配布書類返信ファイルダウンロード
    async downloadDistributionDocumentsReplyFile({ commit }, payload) {
      const { distribution_document_id, distribution_reply_file_id } = payload
      return await this.$axios.get(`/customer/distribution_documents/${distribution_document_id}/distribution_reply_files/${distribution_reply_file_id}/s3_presigned_url`)
    },

    // [顧客]配布書類返信ファイルアップロード
    async uploadDistributionDocumentsReplyFile({ commit }, payload) {
      const { distribution_document_id, distribution_file_id, params } = payload
      const config = payload?.config || null
      return await this.$axios.post(`/customer/distribution_documents/${distribution_document_id}/files/${distribution_file_id}/reply_files`, params, config)
    },

  },

}

export const FILTER = {
  ALL: 0,
  SHOW: 1,
  HIDDEN: 2,
}

export default {
  state: {
    accessibleArticles: [], // ログインユーザーがアクセス可能な物件情報（削除済み含む）
    isFetched: false,
    articles: [],
    filter: FILTER.ALL,
    fipsArticles: [],
    wesArticles: [],
  },

  getters: {
    getArticles: (state) => state.articles,
    getAccessibleArticles: (state) => state.accessibleArticles,
    getArticleFilter: (state) => state.filter,
    isFetched: (state) => state.isFetched,
    getArticleByArticleId: (state) => (article_id) => {
      return state.accessibleArticles.find(article => article.id === parseInt(article_id, 10))
    },
    getFipsArticles: (state) => state.fipsArticles,
    getWesArticles: (state) => state.wesArticles,
  },

  // 同期で状態を変更する（直接stateを変更する）
  mutations: {
    saveAccessibleArticles(state, data) {
      state.accessibleArticles = data
      state.isFetched = true
    },

    saveArticles(state, data) {
      state.articles = data
    },

    saveArticleFilter(state, data) {
      state.filter = data
    },

    // 初期状態に戻す
    // ログイン画面にて実行
    saveClear(state) {
      state.accessibleArticles = []
      state.isFetched = false
      state.articles = []
      state.filter = FILTER.ALL
    },

    saveFipsArticles(state, data) {
      state.fipsArticles = data
    },

    saveWesArticles(state, data) {
      state.wesArticles = data
    },
  },

  // 同期/非同期で状態を変更（mutationsを経由してstateを変更する）
  actions: {
    // ログインユーザーがアクセス可能な物件一覧（削除済み含む）
    //   以下の場面等で使用
    //   - MS-6-0-0-0-0-0 個人設定の関連物件の表示
    //   - ログインユーザーの物件管理者判定（plugins/common.js内のhasManagedArticle）
    async fetchAccessibleArticles({ commit }) {
      const params = {
        // 販売終了物件取得フラグ
        inc_end_of_sales: true,
        // 削除済み物件取得フラグ
        include_deleted: true,
      }

      const response = await this.$axios.get('/articles', { params })

      commit('saveAccessibleArticles', response.data?.body?.data?.articles || [])

      return response
    },

    async fetchArticles({ commit }, requestParams) {
      requestParams = Object.assign(
        {
          // 販売終了物件取得フラグ
          inc_end_of_sales: true,
          // 削除済み物件取得フラグ
          include_deleted: false,
        },
        requestParams,
      )
      await this.$axios.get('/articles', { params: requestParams }).then((response) => {
        if (response.data?.body?.data?.articles) {
          commit('saveArticles', response.data.body.data.articles)
        } else {
          commit('saveArticles', [])
        }
      })
    },

    setArticleFilter({ commit }, params) {
      commit('saveArticleFilter', params)
    },

    clear({ commit }) {
      commit('saveClear')
    },

    // FIPS物件情報一覧取得
    async fetchFipsArticles({ commit }, params) {
      const { article_id } = params
      const res = await this.$axios.get(`/articles/${article_id}/fips_articles`)

      commit('saveFipsArticles', res.data?.body?.data || [])
    },

    // 物件情報のfips_tenant_idを更新する
    async updateFipsTenantId({ commit }, payload) {
      const { articleId, fipsTenantId } = payload
      return await this.$axios.put(`/articles/${articleId}/fips_tenant`,
        { fips_tenant_id: fipsTenantId }
      )
    },

    // WES物件情報一覧取得
    async fetchWesArticles({ commit }, params) {
      const { article_id } = params
      const res = await this.$axios.get(`/articles/${article_id}/wes_articles`)

      commit('saveWesArticles', res.data?.body?.data || [])
    },

    // 物件情報のwes_tenant_idを更新する
    async updateWesTenantId({ commit }, payload) {
      const { articleId, wesTenantId } = payload
      return await this.$axios.put(`/articles/${articleId}/wes_tenant`,
        { wes_tenant_id: wesTenantId }
      )
    },

    // 契約グループ追加
    async addContractGroup({ commit }, payload) {
      const articleId = payload?.articleId || null
      const params = payload?.params || {}
      return await this.$axios.post(`/articles/${articleId}/contracts`, params)
    },

  },
}


import consola from 'consola'

const level = parseInt(process.env.LOG_LEVEL, 10)

export const logger = consola.create({ level })

export default ({ app }, inject) => {
  inject('logger', consola.create({ level }))
}

// 契約書類セット関係者一覧取得
// documentParticipants
// [
// {
//     id,
//     contract_document_id,
//     cloudsign_participant_id,
//     cloudsign_document_id,
//     participant_mail_address,
//     participant_name,
//     participant_organization,
//     participant_order,
//     participant_status,
//     participant_accesscode,
//     contract_info_id,
//     customer_info_id,
//     is_participant_agent,
//     homebuilder_id,
//     customer: {
//          id
//          co_owner_order
//          is_company
//          customer_name
//          company_name
//          company_title
//          customer_mail_address
//          is_agent_enable
//          agent_is_company
//          agent_name
//          agent_company_name
//          agent_company_title
//          agent_mail_address
//     }
//     homebuilder: {
//          id
//          homebuilder_number
//          name
//          mail_address
//     }
// }
// ],

// 契約書類一覧
// [
//  {
//    id
//    cloudsign_file_id
//    contract_document_id
//    contract_display_name
//    contract_file_name
//    sales_agreement_flag
//    delegation_flag
//  }
// ]

export default {
  state: {
    document: {},
    documentFiles: [],
    documentParticipants: [],
  },

  getters: {
    getDocument: (state) => {
      return state.document
    },
    getDocumentFiles: (state) => {
      return state.documentFiles
    },
    getDocumentParticipants: (state) => {
      return state.documentParticipants
    },
  },
  // 同期で状態を変更する（直接stateを変更する）
  mutations: {
    saveDocument(state, data) {
      state.document = data
    },
    saveDocumentFiles(state, data) {
      state.documentFiles = data
    },
    saveDocumentParticipants(state, data) {
      state.documentParticipants = data
    },
  },
  // 同期/非同期で状態を変更（mutationsを経由してstateを変更する）
  actions: {
    // 書類情報取得
    async fetchDocument({ commit }, documentId) {
      await this.$axios.get(`/contract_document/document/${documentId}`).then(function (response) {
        commit('saveDocument', response.data?.body?.data)
      })
    },
    // 書類情報取得(ローディングマスクを表示せずに契約書類セット一覧を取得する)
    async fetchDocumentWithoutLoadingOverlay({ commit }, documentId) {
      await this.$axios.get(`/contract_document/document/${documentId}`, { unShownLoadingOverlay: true }).then(function (response) {
        commit('saveDocument', response.data?.body?.data)
      })
    },
    // 契約書類一覧取得
    async fetchDocumentFiles({ commit }, documentId) {
      await this.$axios.get(`/contract_files/${documentId}`).then(function (response) {
        const files = response.data?.body?.data || []
        commit('saveDocumentFiles', files)
      })
    },
    // 書類セット参加者一覧取得
    async fetchDocumentParticipant({ commit }, documentId) {
      await this.$axios.get(`/contract_participant/${documentId}`).then(function (response) {
        const participants = response.data?.body?.data || []
        commit('saveDocumentParticipants', participants)
      })
    },
    // 書類PDF取得
    async getPdfFile({ commit }, payload) {
      return await this.$axios
        .get(`/local_files/${payload.contract_document_id}/${payload.id}`, { responseType: 'blob' })
        .then(async function (response) {
          const blob = await new Blob([response.data], { type: 'application/pdf' })
          return URL.createObjectURL(blob)
        })
    },

    // 契約書類セット一時保存
    async postDocument({ commit }, payload) {
      const { documentId, form } = payload
      // パラメータ
      //   contract_id
      //   is_created_signed
      //   phase_id
      //   homebuilder_id
      //   delegation_customer_info_id
      //   delegation_flag
      //   send_user_list
      //   partner_id_list
      // return
      //   result.data.body.data.contract_document_id
      if (documentId) {
        // 更新
        return await this.$axios.post(`/contract_documents/${documentId}`, form)
      }
      // 追加
      return await this.$axios.post('/contract_documents', form)
    },

    // 書類セット契約書類更新
    async updateDocumentFiles({ commit }, payload) {
      const { documentId, file_list } = payload
      const config = payload?.config || null
      return await this.$axios.post(`/contract_files/${documentId}/file`, file_list, config)
    },

    // 契約書類セット削除
    async deleteDocument({ commit }, documentId) {
      return await this.$axios.delete(`/contract_documents/${documentId}`)
    },

    // 承認依頼
    async approve({ commit }, payload) {
      const { documentId } = payload
      return await this.$axios.post(`/contract_documents/${documentId}/approval`)
    },

    // 承認
    async acceptApproval({ commit }, documentId) {
      return await this.$axios.put(`/contract_documents/${documentId}/approval`,
        // 3: 承認済 / 2: 承認差戻
        { document_status: 3 }
      )
    },

    // 承認差戻
    async rejectApproval({ commit }, payload) {
      const { documentId, rejectReason } = payload
      return await this.$axios.put(`/contract_documents/${documentId}/approval`,
        // 3: 承認済 / 2: 承認差戻
        { document_status: 2, return_reason: rejectReason }
      )
    },

    // 承認依頼取り消し
    async deleteApproval({ commit }, documentId) {
      return await this.$axios.delete(`/contract_documents/${documentId}/approval`)
    },

    // 書類セット署名依頼
    async sendContractDocument({ commit }, params) {
      const config = params?.config || null
      const documentId = params.documentId
      return await this.$axios.post(`/contract_documents/${documentId}/send`, null, config)
    },

    // 送信取消
    async deleteSending({ commit }, documentId) {
      return await this.$axios.delete(`/contract_documents/${documentId}/send`)
    },

    // クラウドサインリマインド送信
    async remindCloudSign({ commit }, documentId) {
      return await this.$axios.post(`/contract_documents/${documentId}/remind`)
    }
  },
}
